import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

import store from '@/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'EXEVE'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Logiciel de vente aux enchères complet',
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/Legal.vue'),
    meta: {
      title: 'Mentions légales',
    },
  },
  {
    path: '/exeve-logiciel-vente-aux-encheres-application-mobile',
    name: 'about',
    component: () => import(/* webpackChunkName: "legal" */ '../views/About.vue'),
    meta: {
      title: 'Qui sommes-nous ?',
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Login',
      nav: 'login',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      nav: 'dashboard',
    },
  },
  {
    path: '/password_reset',
    name: 'passwordReset',
    component: () => import(/* webpackChunkName: "passwordReset" */ '../views/PasswordReset.vue'),
    meta: {
      title: 'Mot de passe oublié',
    },
  },
  {
    path: '/recover/:token?',
    name: 'passwordUpdate',
    component: () => import(/* webpackChunkName: "passwordUpdate" */ '../views/PasswordUpdate.vue'),
    meta: {
      title: 'Mise à jour du mot de passe',
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/contacts/List.vue'),
    meta: {
      title: 'Contacts',
      nav: 'contacts',
    },
  },
  {
    path: '/contact/:id/edit',
    name: 'contact_edit',
    component: () => import(/* webpackChunkName: "contact_edit" */ '../views/contacts/Edit.vue'),
    meta: {
      title: 'Éditer un contact',
      nav: 'contacts',
    },
  },
  {
    path: '/contact/new',
    name: 'contact_create',
    component: () => import(/* webpackChunkName: "contact_create" */ '../views/contacts/Create.vue'),
    meta: {
      title: 'Ajouter un contact',
      nav: 'contacts',
    },
  },
  {
    path: '/inventories',
    name: 'inventories',
    component: () => import(/* webpackChunkName: "inventories" */ '../views/inventories/List.vue'),
    meta: {
      title: 'Inventaires',
      nav: 'inventories',
    },
  },
  {
    path: '/inventory/new',
    name: 'inventory_create',
    component: () => import(/* webpackChunkName: "inventory_create" */ '../views/inventories/Create.vue'),
    meta: {
      title: 'Ajouter un inventaire',
      nav: 'inventories',
    },
  },
  {
    path: '/inventory/:inventoryId/edit',
    name: 'inventory_edit',
    component: () => import(/* webpackChunkName: "inventory_edit" */ '../views/inventories/Edit.vue'),
    meta: {
      title: 'Éditer un inventaire',
      nav: 'inventories',
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '../views/invoices/List.vue'),
    meta: {
      title: 'Invoices',
      nav: 'invoices',
    },
  },
  {
    path: '/invoice/:id/edit',
    name: 'invoice_edit',
    component: () => import(/* webpackChunkName: "invoice_edit" */ '../views/invoices/Edit.vue'),
    meta: {
      title: 'Editer une facture',
      nav: 'invoices',
    },
  },
  {
    path: '/invoice/new',
    name: 'invoice_create',
    component: () => import(/* webpackChunkName: "invoice_create" */ '../views/invoices/Create.vue'),
    meta: {
      title: 'Ajouter une facture',
      nav: 'invoices',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/products/List.vue'),
    meta: {
      title: 'Products',
      nav: 'products',
    },
  },
  {
    path: '/product/:id/edit',
    name: 'product_edit',
    component: () => import(/* webpackChunkName: "product_edit" */ '../views/products/Edit.vue'),
    meta: {
      title: 'Editer un produit',
      nav: 'products',
    },
  },
  {
    path: '/product/new',
    name: 'product_create',
    component: () => import(/* webpackChunkName: "product_create" */ '../views/products/Create.vue'),
    meta: {
      title: 'Ajouter un produit',
      nav: 'products',
    },
  },
  {
    path: '/accounting',
    name: 'accounting',
    component: () => import(/* webpackChunkname: "accounting" */ '../views/accounting/List.vue'),
    meta: {
      title: 'Comptabilité',
      nav: 'accounting',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkname: "payments" */ '../views/payments/List.vue'),
    meta: {
      title: 'Liste des paiements',
      nav: 'payments',
    },
  },
  {
    path: '/bank-deposits',
    name: 'bank-deposits',
    component: () => import(/* webpackChunkname: "bank_deposits" */ '../views/bankDeposits/List.vue'),
    meta: {
      title: 'Liste des remises en banque',
      nav: 'bank-deposits',
    },
  },
  {
    path: '/requisitions',
    name: 'requisitions',
    component: () => import(/* webpackChunkName: "requisitions" */ '../views/requisitions/List.vue'),
    meta: {
      title: 'Réquisitions',
      nav: 'requisitions',
    },
  },
  {
    path: '/requisition/new',
    name: 'requisition_create',
    component: () => import(/* webpackChunkName: "requisition_create" */ '../views/requisitions/Create.vue'),
    meta: {
      title: 'Réquisitions',
      nav: 'requisitions',
    },
  },
  {
    path: '/requisition/:requisitionId/edit',
    name: 'requisition_edit',
    component: () => import(/* webpackChunkName: "requisition_edit" */ '../views/requisitions/Edit.vue'),
    meta: {
      title: 'Éditer une réquisition',
      nav: 'requisitions',
    },
  },
  {
    path: '/requisitionitem/:itemId/edit',
    name: 'requisition_item_edit',
    component: () => import(/* webpackChunkname: "requisition_item_edit" */ '../views/requisitionItems/Edit.vue'),
    meta: {
      title: 'Éditer un lot de réquisition',
      nav: 'requisitions',
    },
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import(/* webpackChunkname: "sales" */ '../views/sales/List.vue'),
    meta: {
      title: 'Ventes',
      nav: 'sales',
    },
  },
  {
    path: '/sale/new',
    name: 'sale_create',
    component: () => import(/* webpackChunkname: "sale_create" */ '../views/sales/Create.vue'),
    meta: {
      title: 'Ventes',
      nav: 'sales',
    },
  },
  {
    path: '/sale/:saleId/edit',
    name: 'sale_edit',
    component: () => import(/* webpackChunkName: "sale_edit" */ '../views/sales/Edit.vue'),
    meta: {
      title: 'Éditer une vente',
      nav: 'sales',
    },
  },
  {
    path: '/sale/:saleId/dashboard',
    name: 'sale_dashboard',
    component: () => import(/* webpackChunkName: "sale_dashboard" */ '../views/sales/Dashboard.vue'),
    meta: {
      title: "Panneau de contrôle d'une vente",
      nav: 'sales',
    },
  },
  {
    path: '/sales/book',
    name: 'sales_book',
    component: () => import(/* webpackChunkName: "sales_book" */ '../views/sales/Book.vue'),
    meta: {
      title: 'Répertoire des ventes',
      nav: 'sales_book',
    },
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import(/* webpackChunkName: "stocks" */ '../views/stocks/List.vue'),
    meta: {
      title: 'Liste des stocks',
      nav: 'stocks',
    },
  },
  {
    path: '/books/',
    name: 'books_general',
    component: () => import(/* webpackChunkName: "books_general" */ '../views/books/General.vue'),
    meta: {
      title: 'Livre général',
      nav: 'books_general',
    },
  },
  {
    path: '/books/weapons',
    name: 'books_weapons',
    component: () => import(/* webpackChunkName: "books_weapons" */ '../views/books/Weapons.vue'),
    meta: {
      title: 'Livre des armes',
      nav: 'books_weapons',
    },
  },
  {
    path: '/books/police',
    name: 'books_police',
    component: () => import(/* webpackChunkName: "books_police" */ '../views/books/Police.vue'),
    meta: {
      title: 'Livre de police',
      nav: 'books_police',
    },
  },
  {
    path: '/trash',
    name: 'trash',
    component: () => import(/* webpackChunkName: "trash" */ '../views/Trash/List.vue'),
    meta: {
      title: 'Corbeille',
      nav: 'trash',
    },
  },
  {
    path: '/activity-log',
    name: 'activity_log',
    component: () => import(/* webpackChunkName: "activity_log" */ '../views/activityLog/List.vue'),
    meta: {
      title: "Journal d'activité",
      nav: 'activity_log',
    },
  },
  {
    path: '/my-account',
    name: 'my_account',
    component: () => import(/* webpackChunkName: "my_account" */ '../views/myAccount/Edit.vue'),
    meta: {
      title: 'Mon compte',
      nav: 'my_account',
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(from, to) {
    if (to.query.backScrollTo) {
      store.commit('setScrollPosition', to.query.backScrollTo)
    } else {
      store.commit('setScrollPosition', 0)
    }

    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Checking auth
    if (Vue.$cookies.get('authToken')) {
      next()
    } else {
      next({
        name: 'login',
      })
    }
  } else {
    if (from.query.scrollTo) {
      next({ query: { scrollTo: from.query.scrollTo.toString() } })
    } else {
      // Route hijack: If we're coming from a requisition item form and want to go to a new item, let's redirect to items list
      // so that a new item doesn't get created each time
      next()
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title ? `${to.meta.title} | EXEVE ${process.env.VUE_APP_VERSION}` : DEFAULT_TITLE
  })
})

// Forces browser reload on chunk load error
router.onError((error) => {
  // eslint-disable-next-line no-console
  console.log('Erreur detectée par le routeur : ' + error.message)
  if (/Loading chunk/i.test(error.message) && navigator.onLine) {
    // eslint-disable-next-line no-console
    console.log('ChunkLoadError detectée par le routeur : ' + error.message)
    window.location.reload()
  }
})

export default router
