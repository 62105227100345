






import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    // In case of a hard reload, the store might have to be refreshed
    if (this.$cookies.get('authToken') && this.user == null) {
      this.loadAppData().catch((error) => {
        if (error.response?.status === 401) {
          this.logoutUser()
        }
      })
    }
  },
  methods: {
    ...mapActions(['loadAppData', 'logout']),
    logoutUser() {
      // Destroying user inside store, removing token and redirecting to home
      this.logout()
      this.$cookies.remove('authToken')

      // Destroying local storage
      localStorage.clear()

      if (this.$router.currentRoute.name != 'home') {
        this.$router.push({ name: 'home' })
      }
    },
  },
})
