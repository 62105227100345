import Vue from 'vue'
import '@/styles/element-variables.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import axiosInstance from '@/axios'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(VueCookies)
Vue.use(VueDOMPurifyHTML)

// ElementUI
import {
  Button,
  Row,
  Col,
  Container,
  Header,
  Main,
  Aside,
  Alert,
  Input,
  InputNumber,
  Form,
  FormItem,
  Table,
  TableColumn,
  Pagination,
  Menu,
  MenuItem,
  Submenu,
  Loading,
  Select,
  Option,
  OptionGroup,
  Message,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  MessageBox,
  Link,
  Radio,
  RadioGroup,
  RadioButton,
  Tag,
  Tabs,
  TabPane,
  Autocomplete,
  Dialog,
  DatePicker,
  Image,
  Notification,
  Avatar,
  Progress,
  Popover,
  Switch,
  Cascader,
  TimeSelect,
  Timeline,
  TimelineItem,
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/fr'
import locale from 'element-ui/lib/locale'

locale.use(lang)
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Alert)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Link)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Tag)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Autocomplete)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Avatar)
Vue.use(Progress)
Vue.use(Popover)
Vue.use(Switch)
Vue.use(Cascader)
Vue.use(TimeSelect)
Vue.use(Timeline)
Vue.use(TimelineItem)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$alert = MessageBox.alert

// Styles
import 'ress'
import '@/styles/main.scss'

Vue.config.productionTip = false

// Making axios available globally and binding it to the this.$api method
Vue.prototype.$api = axiosInstance

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

//fix el-select
const fixElSelect = function () {
  document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach(() => {
    const elInput = document.querySelector(
      '.el-select[data-is-search="true"]:hover input[readonly]'
    ) as HTMLInputElement
    if (elInput) {
      elInput.readOnly = false
      elInput.blur()
      elInput.focus()
    }
  })
}
document.addEventListener('focusin', fixElSelect)
document.addEventListener('click', fixElSelect)
document.addEventListener('touchstart', fixElSelect)
